var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"custom-border border pt-3"},[(_vm.course_id)?_c('section',{staticClass:"d-flex flex-row justify-space-between align-center mb-3 mr-2"},[_c('v-btn',{staticClass:"flat fw600",attrs:{"dense":"","color":"primary","text":""},on:{"click":() => {
            _vm.course_id = null
            _vm.title = null
            _vm.selected = 'All'
            _vm.$emit('closeList')
        }}},[_c('v-icon',[_vm._v(" mdi-chevron-left ")]),_vm._v(" "+_vm._s(_vm.title)+" ")],1),_c('v-select',{staticClass:"col-xl-3 col-lg-4 f14 fw500 poppins custom-border general-custom-field",attrs:{"items":_vm.filter,"hide-details":"","solo":"","outlined":"","dense":"","flat":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1):_vm._e(),_c('v-expand-transition',[_c('v-data-table',{staticClass:"elevation-1 custom-border border",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search},scopedSlots:_vm._u([{key:"item.title",fn:function({ item }){return [_c('span',{staticClass:"roboto text-wrap pa-2 text-right ma-0 primary--text cursor-pointer fw600",style:('letter-spacing: normal; justify-content: flex-start;'),attrs:{"text":""},on:{"click":() => {
                    if(item.enrollments_count > 0) {
                        _vm.course_id = item.id
                        _vm.title = item.title
                        _vm.$emit('changeGraphData', { filter: 'All', course_id: item.id})
                        _vm.$emit('viewList', { course_id: item.id, title: item.title })
                    }
                }}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.start",fn:function({ item }){return [_vm._v(" "+_vm._s(item.start ? _vm.$dateFormat.mmDDyy(item.start) : '-')+" ")]}},{key:"item.end",fn:function({ item }){return [_vm._v(" "+_vm._s(item.end ? _vm.$dateFormat.mmDDyy(item.end) : '-')+" ")]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }