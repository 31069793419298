<template>
    <section id="reporting">
        <v-row class="mb-5">
            <v-col cols="12" md="3" v-for="(item, i) in items" :key="i">
                <TopCard :item="item"/>
            </v-col>
        </v-row>
        <v-card class="custom-border border pa-5 mb-5">
            <HorizontalBarGraph 
                class="my-5"
                :chartData="chartData">
            </HorizontalBarGraph>
        </v-card>
        <section class="d-flex flex-wrap justify-space-between">
            <v-text-field
                solo
                dense
                flat
                class="col-xl-3 col-lg-4 custom-border mb-3 general-custom-field f14 poppins fw500 px-0"
                hide-details
                prepend-inner-icon="mdi-magnify"
                placeholder="Search"
                v-model="search"
            />
            <v-btn dense outlined color="primary" @click="exportTable">
                Export List
            </v-btn>
        </section>
        

        <!-- <v-select
          :items="filter"
          v-model="selected"
          hide-details
          solo
          dense 
          flat
          class="col-xl-3 col-lg-4 f14 fw500 poppins custom-border general-custom-field mb-5">
        </v-select> -->
        <!-- <canvas ref="chart"  style="width: 300px;"></canvas> -->
        <ReportingTableVue :headers="headers" :items="table_items" :search="search" @viewList="viewList" @closeList="init" @changeGraphData="changeGraphData"/>
    </section>
</template>

<script>
import { mapActions } from 'vuex';
import * as XLSX from 'xlsx';
import TopCard from '@/components/reusable/TopCard.vue'
import HorizontalBarGraph from '@/components/reusable/HorizontalBarGraph.vue'
import ReportingTableVue from '@/components/teacher/reporting/ReportingTable.vue';

export default ({
    components: {
        TopCard,
        HorizontalBarGraph,
        ReportingTableVue
    },
    data: () => ({
        items: [{
            id: 'total_enrolled_users',
            title: 'Total Enrolled Users',
            count: '-',
            color: '#B5221033', 
            outline: '#B52210'
        },
        {
            id: 'published_courses',
            title: 'Published Courses',
            count: '-',
            color: '#F8835E33',
            outline: '#F8835E'
        },
        {
            id: 'total_admins_and_facilitators',
            title: 'Total Admins & Facilitators',
            count: '-',
            color: '#E49E3233',
            outline: '#E49E32'
        }],
        courses: {},
        chartData: {
            labels: [],
            datasets: [
                {
                    label: 'No of Enrollees',
                    data: [],
                    borderWidth: 1
                }
            ]
        },
        headers: [ 
            { text: "COURSE", value: "title"},
            { text: "ENROLLED USERS", value: "enrollments_count", align: 'center' },
            { text: "TRAINING HOURS", value: "training_hours", align: 'center' },
            { text: "DATE CONDUCTED", value: "start", align: 'center' },
            { text: "END", value: "end", align: 'center' },
        ],
        table_items: [],
        search: null,
        filter: 'All',
        selected_course: null,
    }),
    methods: {
        ...mapActions('instructor', ['getInstructorReportingStats', 'getInstructorCoursesReportingStats', 'getInstructorEnrolledUsersAction', 'getRegistrationFieldsAction', 'getInstructorFilteredEnrolledUsersAction']),
        
        changeGraphData(val){
            this.filter = val

            if(this.headers.length > 2) {
                this.headers.pop()
            }
            if(this.filter.filter !== 'All') {
                this.headers.push({ text: `${this.filter.filter.toUpperCase()}`, value: `${this.filter.filter.toLowerCase()}`, align: 'center' })
            }

            this.getInstructorFilteredEnrolledUsersAction({ filter: val.filter, course_id: val.course_id}).then(res => {
                this.chartData = {
                    labels: Object.keys(res),
                    datasets: [
                        {
                            label: 'No of Enrollees',
                            data: Object.values(res),
                            backgroundColor: this.$vuetify.theme.currentTheme.primary,
                            borderColor: this.$vuetify.theme.currentTheme.primary,
                            borderWidth: 1
                        }
                    ]
                }
            })

            this.table_items = this.table_items
        },

        exportTable(){
            const worksheet = XLSX.utils.json_to_sheet(this.table_items)
            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, worksheet, ``)
            alert(this.selected_course)
            if(this.selected_course) {
                XLSX.writeFile(wb,`Instructor Analytics - ${this.selected_course} users.xlsx`)
            } else {
                XLSX.writeFile(wb,`Instructor Analytics Table.xlsx`)
            }
        },
        
        viewList(item) {
            console.log(item)
            this.selected_course = item.title
            this.getInstructorEnrolledUsersAction(item.course_id).then(res => {
                this.table_items = []
                res.forEach(user => {
                    let _user = {}
                    _user['user'] = `${ user.first_name ? user.first_name : 'User'} ${ user.last_name ? user.last_name : 'Account'} ${ user.suffix ? user.suffix : ''}`
                    _user['email'] = user.email
                    if(user.data) {
                        for (const [key1, value1] of Object.entries(user.data)) {
                            for (const [key2, value2] of Object.entries(value1)) {
                                _user[this.getLabel(`${key2.replaceAll('_', " ")}`)] = value2
                            }
                        }
                    }
                    this.table_items.push(_user)
                })
            })

            this.headers = [ 
                { text: "USER", value: "user"},
                { text: "EMAIL", value: "email", align: 'center' },
            ]
        },

        
        getLabel(_label) {
            let initials = []
            let word = _label.split(' ')
            word.forEach(item => {
                initials.push(`${item.toLowerCase()}`)
            })
            let label = initials.join(' ')
            return  `${label}`
        },

        init() {
            this.selected_course = null
            this.table_items = []
            this.headers =  [ 
                { text: "COURSE", value: "title"},
                { text: "ENROLLED USERS", value: "enrollments_count", align: 'center' },
                { text: "TRAINING HOURS", value: "training_hours", align: 'center' },
                { text: "DATE CONDUCTED", value: "start", align: 'center' },
                { text: "END", value: "end", align: 'center' },
            ],
            this.getInstructorCoursesReportingStats().then(res => {
                let courses = {}
                res.forEach(item => {
                    let _course = {}
                    courses[item.title ? item.title : 'Untitled'] = item.enrollments_count
                    _course['id'] = item.id
                    _course['title'] = item.title ? item.title : 'Untitled'
                    _course['enrollments_count'] = item.enrollments_count
                    _course['training_hours'] = item.training_hours
                    _course['start'] = item.available_until ? item.available_until.start_date ? item.available_until.start_date : null : null
                    _course['end'] = item.available_until ? item.available_until.end_date ? item.available_until.end_date : null : null

                    this.table_items.push(_course)
                })
                this.chartData = {
                    labels: Object.keys(courses),
                    datasets: [
                        {
                            label: 'No of Enrollees',
                            data: Object.values(courses),
                            backgroundColor: this.$vuetify.theme.currentTheme.primary,
                            borderColor: this.$vuetify.theme.currentTheme.primary,
                            borderWidth: 1
                        }
                    ]
                }
            })
        }
    },
    mounted(){

        this.chartData.datasets[0].backgroundColor = this.$vuetify.theme.currentTheme.primary
        this.chartData.datasets[0].borderColor = this.$vuetify.theme.currentTheme.primary
        
        this.getInstructorReportingStats().then(res => {
            for (const [key, value] of Object.entries(res)) {
                this.items[this.items.findIndex(item  => item.id === key)].count = value
            }
        })

        this.init()
    },
})
</script>