<template>
    <v-card class="custom-border border pt-3">
        <section class="d-flex flex-row justify-space-between align-center mb-3 mr-2" v-if="course_id">
            <v-btn @click="() => {
                course_id = null
                title = null
                selected = 'All'
                $emit('closeList')
            }"
                class="flat fw600" dense color="primary" text>
                <v-icon>
                    mdi-chevron-left
                </v-icon>
                {{ title }}
            </v-btn>
            <v-select
                :items="filter"
                v-model="selected"
                hide-details
                solo
                outlined
                dense 
                flat
                class="col-xl-3 col-lg-4 f14 fw500 poppins custom-border general-custom-field">
            </v-select>
        </section>
        <v-expand-transition>
            <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                class="elevation-1 custom-border border"
                >
                <template v-slot:item.title="{ item }">
                    <span @click="() => {
                        if(item.enrollments_count > 0) {
                            course_id = item.id
                            title = item.title
                            $emit('changeGraphData', { filter: 'All', course_id: item.id})
                            $emit('viewList', { course_id: item.id, title: item.title })
                        }
                    } " text class="roboto text-wrap pa-2 text-right ma-0 primary--text cursor-pointer fw600" :style="'letter-spacing: normal; justify-content: flex-start;'">
                        {{ item.title }}
                    </span>
                </template>
                <template v-slot:item.start="{ item }">
                    {{ item.start ? $dateFormat.mmDDyy(item.start) : '-' }}
                </template>
                <template v-slot:item.end="{ item }">
                    {{ item.end ? $dateFormat.mmDDyy(item.end) : '-' }}
                </template>
            </v-data-table>
        </v-expand-transition>
    </v-card>
</template>

<script>
import Section from '@/views/admin/Section.vue'
import { mapActions } from 'vuex'

export default {
  components: { Section },
    props: ['headers', 'items', 'search'],
    data: () => ({
        course_id: null,
        title: '',
        selected: 'All',
        filter: []
    }),
    methods: {
        ...mapActions('instructor', ['getRegistrationFilterAction', 'getInstructorFilteredEnrolledUsersAction']),
    },
    watch: {
        course_id(){
            this.getRegistrationFilterAction().then(res => {
                res.push('All')
                this.filter = res
            })
        },

        selected(val) {
            this.$emit('changeGraphData', { filter: val, course_id: this.course_id})
        }
    }
}
</script>